import PropTypes from 'prop-types';
import React, { createContext } from 'react';

import dynamic from 'next/dynamic';
import { snakeToPascal } from '../../utils/caseconverters';
import styles from './StreamField.module.scss';

const TextBlock = dynamic(() => import('../TextBlock'));
const RichTextBlock = dynamic(() => import('../RichText'));
const CrossLinks = dynamic(() => import('../CrossLinks'));
const ArticleBlock = dynamic(() => import('../ArticleBlock'));
const CardListLocals = dynamic(() => import('../CardListLocals'));
const CardList = dynamic(() => import('../../components/CardList'));
const InTheSpotlight = dynamic(() => import('../../components/InTheSpotlight'));
const GuideList = dynamic(() => import('../../components/GuideList'));
const EventPageContent = dynamic(() =>
    import('../../components/EventPageContent')
);
const FeaturedEvent = dynamic(() => import('../../components/FeaturedEvent'));
const GuideTeaser = dynamic(() => import('../../components/GuideTeaser'));
const LocalsPresentation = dynamic(() =>
    import('../../components/LocalsPresentation')
);
const EventSubmission = dynamic(() =>
    import('../../components/EventSubmission')
);
const ObjectList = dynamic(import('../ObjectList'));
const FeaturedArticles = dynamic(() => import('../FeaturedArticles'));
const Video = dynamic(() => import('../Video'));
const BlockQuote = dynamic(() => import('../BlockQuote'));
const Form = dynamic(() => import('../Form'));
const FactBox = dynamic(() => import('../FactBox'));
const CardImageLink = dynamic(() => import('../CardImageLink'));
const VideoCarousel = dynamic(() => import('../../components/VideoCarousel'));
const ImageWithCaption = dynamic(() =>
    import('../../components/ImageWithCaption')
);
const WindowTeaser = dynamic(() => import('../../components/WindowTeaser'));
const WindowTeaserWhite = dynamic(() =>
    import('../../components/WindowTeaserWhite')
);
const CampaignEntry = dynamic(() => import('../CampaignEntry'));
const CampaignShapes = dynamic(() => import('../CampaignShapes'));
const CampaignBanner = dynamic(() => import('../CampaignBanner'));
const CampaignDirectionBlock = dynamic(() =>
    import('../CampaignDirectionBlock')
);
const CTACampaignCard = dynamic(() => import('../CTACampaignCard'));
const LocalsTip = dynamic(() => import('../LocalsTip'));
const CardGridBlock = dynamic(() => import('../CardGridBlock'));
const DistrictMap = dynamic(() => import('../DistrictMap'));
const NotesListBlock = dynamic(() => import('../NotesListBlock'));
const ImageGridBlock = dynamic(() => import('../ImageGridBlock'));
const TeaserGridBlock = dynamic(() => import('../TeaserGridBlock'));
const NumberedListBlock = dynamic(() => import('../NumberedListBlock'));
const ObjectCounterBlock = dynamic(() => import('../ObjectCounterBlock'));
const AnimatedPlattanBlock = dynamic(() => import('../AnimatedPlattanBlock'));
const EventListBlock = dynamic(() => import('../EventListBlock'));

const Components = {
    TextBlock,
    RichTextBlock,
    CrossLinksBlock: CrossLinks,
    CardListBlock: CardList,
    CardImageLink,
    ArticleBlock,
    FilteredEventListBlock: CardList,
    CardListTwoArticles: CardList,
    CardListLocals,
    CardList,
    GuideList,
    EventPageContent,
    FeaturedEvent,
    GuideTeaser,
    LocalsPresentation,
    EventSubmission,
    ObjectList,
    FeaturedArticles,
    Video,
    BlockQuote,
    Form,
    FactBox,
    VideoCarousel,
    ImageWithCaption,
    WindowTeaser,
    WindowTeaserWhite,
    CampaignEntry,
    CampaignShapes,
    CampaignBanner,
    CampaignDirectionBlock,
    CtaCampaignCard: CTACampaignCard,
    LocalsTip,
    CardGridBlock,
    DistrictMap,
    NotesListBlock,
    ImageGridBlock,
    TeaserGridBlock,
    NumberedListBlock,
    ObjectCounterBlock,
    AnimatedPlattanBlock,
    PopularEvents: EventListBlock,
    PopularEventsByCategory: EventListBlock,
    LastChanceByCategory: EventListBlock,
    GuideEvents: EventListBlock,
    InTheSpotlight,
};

const ComponentColors = {
    RichTextBlock: 'White',
    CrossLinksBlock: 'White',
    CardListBlock: '',
    ArticleBlock: 'White',
    FilteredEventListBlock: 'Mixed',
    CardListTwoArticles: 'Black',
    CardListLocals: 'Mixed',
    CardList: 'Black',
    GuideList: 'Mixed',
    EventPageContent: 'Black',
    FeaturedEvent: 'Top',
    GuideTeaser: '',
    FeaturedArticles: 'Half',
    Video: 'White',
    VideoCarousel: 'White',
    ImageWithCaption: 'White',
    WindowTeaser: '',
    WindowTeaserWhite: '',
    CampaignEntry: 'Mixed',
    CampaignShapes: '',
    CampaignBanner: '',
    CampaignDirectionBlock: '',
    CTACampaignCard: '',
    LocalsTip: '',
    CardGridBlock: '',
    DistrictMap: '',
    NotesListBlock: '',
    ImageGridBlock: '',
    TeaserGridBlock: '',
    NumberedListBlock: '',
    ObjectCounterBlock: '',
    AnimatedPlattanBlock: '',
};

const TrackingContext = createContext(null);

const StreamField = ({
    items = [],
    componentWrapperExtraClass = '',
    modifiers = [],
    experiments,
    fromStartpage,
    fromEventList,
    fromCategoryPage,
}) => {
    if (!items.length) {
        return null;
    }
    let bannerLevel = 0;

    const dynamicComponents = items.map((item, index) => {
        item.component = snakeToPascal(item.type);

        if (
            [
                'FeaturedArticles',
                'CardListLocals',
                'CardListBlock',
                'FilteredEventListBlock',
                'GuideTeaser',
                'ArticleBlock',

                // From event list page
                'PopularEvents',
                'PopularEventsByCategory',
                'LastChanceByCategory',
                'InTheSpotlight',
            ].includes(item.component)
        ) {
            bannerLevel++;
        }

        item.color = item.value?.color || ComponentColors[item.component] || '';
        const Component = Components[item.component];

        if (!Component) {
            return null;
        }

        // Hide InTheSpotlight component if no future event
        if (item.component === 'InTheSpotlight' && item.value.noFutureEvent) {
            return null;
        }

        const shouldTrackBannerLevel =
            fromStartpage || fromEventList || fromCategoryPage;

        return (
            <div
                key={item.id}
                id={`sf-${item.id}`}
                className={[
                    ...[
                        modifiers.map(
                            (x) => styles[`StreamField__Component--${x}`]
                        ),
                    ],
                    styles['StreamField__Component'],
                    styles[`StreamField__Component--${item.component}`],
                    styles[`StreamField__Component--${item.color}`],
                    componentWrapperExtraClass,
                ].join(' ')}>
                <TrackingContext.Provider
                    value={shouldTrackBannerLevel ? bannerLevel : null}>
                    <Component
                        id={item.id}
                        {...item.value}
                        color={item.color}
                        experiments={experiments}
                        streamFieldPosition={index}
                        streamFieldTotals={items.length}
                    />
                </TrackingContext.Provider>
            </div>
        );
    });

    return <>{dynamicComponents}</>;
};

StreamField.propTypes = {
    items: PropTypes.array,
    componentWrapperExtraClass: PropTypes.string,
};

export default StreamField;

export { ComponentColors, TrackingContext };
